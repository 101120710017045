import React from 'react';
import Page from '../components/Page';
import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Sponsor from '../components/Sponsor';
import {Markdown} from '../components/Markdown';
import {useSiteData} from '../hooks/useSiteData';
import {graphql, Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"
import {Container, Row, Col} from 'react-bootstrap';
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";
import Seo from "../components/seo";
import InstagramIcon from "../assets/images/icons/logo_Instagram_Glyph_Gradient.png";

const optionsGallery = {
	settings: {
		overlayColor: "rgb(25, 136, 124)",
		autoplaySpeed: 3000,
		transitionSpeed: 900,
	},
	buttons: {
		backgroundColor: "#1b5245",
		iconColor: "rgba(126, 172, 139, 0.8)",
		showDownloadButton: false,
	},
	caption: {
		captionColor: "#a6cfa5",
		captionFontFamily: "Raleway, sans-serif",
		captionFontWeight: "300",
		captionTextTransform: "uppercase",
	},
	thumbnails: {
		/*		showThumbnails: false,*/
		thumbnailsAlignment: 'center',
		thumbnailsContainerBackgroundColor: 'transparent',
		thumbnailsContainerPadding: '0',
		thumbnailsGap: '0 0px',
		thumbnailsIconColor: '#ffffff',
		thumbnailsOpacity: 0.4,
		thumbnailsPosition: 'bottom',
		thumbnailsSize: ['100px', '80px']
	},
};


export default function books({data: {books, allCharlieBookPage, global}}) {
	/*	const {advertise} = useSiteData();*/

	return <Page orientation="portrait">

		<Seo remoteMetaData={books} title={books.titleSEO} />



		<div className="game-box">
			<div className="game-box-inner">
				<header className="header-area">
					<div className="container">
						<div className="header-grid">
							<div className="box-1">
							</div>
							<div className="box-2"/>

							<Menu/>
						</div>
					</div>
				</header>

				<div className="content" style={{paddingBottom: 91.3333}}>
					<div className="gen-page nav-grid">
						<div className="logo-box">
							<Link to="/">
								<Logo/>
							</Link>
						</div>
						<div className="button-box">
							<a href="https://www.instagram.com/keenkidsglobal/" target="_blank"
							   className="ico-social-instagram-wrap">
								<img src={InstagramIcon} alt="Instagram logo"
									 className="ico-social-instagram"/>
							</a>
							<a href="https://shop.keenkids.com.au" target="_blank"
							   className="kk-btn-rounded btn-primary3">Shop</a>
						</div>
					</div>


					<h1>{books.header}</h1>


					<Markdown>{books.bodyText}</Markdown>

					<a href="/charlie-the-keen-kid-book/index.html" target="_blank"
					   className="kk-btn-rounded btn-primary3 buy-book">Read the Book For Free</a>
					<a href={global.bookAmazonURL} target="_blank" className="kk-btn-rounded btn-primary3 buy-book">Buy
						The Soft Cover</a>
					<a href="https://shop.keenkids.com.au" target="_blank"
					   className="kk-btn-rounded btn-primary3 buy-book">Buy The Hard Cover - Australia only</a>

					<Markdown>{books.bodyText2}</Markdown>

					<Container>
						<div className="srl-wrapper">
							<SimpleReactLightbox>
								<SRLWrapper options={optionsGallery}>
									<Row>
										{allCharlieBookPage.edges.map(({node}) => (
											<Col lg={6} md={6} sm={6} xs={6} key={node.id} className="py-3">
												{/* {node.base.split('-').join(' ').split('.')[0]} */}
												<a href={node.image.localFile.publicURL}>
													<GatsbyImage
														image={node.image.localFile.childImageSharp.gatsbyImageData}
														alt={node.altText}/>
												</a>
												<caption>{node.caption}</caption>
											</Col>
										))}
									</Row>
								</SRLWrapper>
							</SimpleReactLightbox>
						</div>
					</Container>
					<a href="https://shop.keenkids.com.au" target="_blank"
					   className="kk-btn-rounded btn-primary3 buy-book">Buy The Hard Cover - Australia only</a>

					<a href={global.bookAmazonURL} target="_blank" className="kk-btn-rounded btn-primary3 buy-book">Buy
						The Soft Cover</a>
					<Sponsor/>
				</div>
				{/* / .content */}
			</div>
			{/* / .game-box-inner */}
		</div>
		{/* / .game-box */}
	</Page>;
}

export const query = graphql`
	query {
		books {
			header
			bodyText
			bodyText2
			titleSEO
			descriptionSEO
			ogImage {
				localFile {
					publicURL
				}
			}
		}
		global{
			bookAmazonURL
		}
		allCharlieBookPage {
			edges {
				node {
					altText
					active
					caption
					id
					image {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									height: 600
									width: 900
									transformOptions: {fit: COVER}
									placeholder: BLURRED
									webpOptions: {quality: 50}
								)
							}
						}
					}
				}
			}
		}
		
    }
`;
